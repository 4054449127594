export const USERLIST = "admin/user/userList";

export const USERLISTPREMIUM = "admin/user/premiumUsers";

export const USER_DETAILS = "admin/user/detail";

export const REMOVE_IMAGES = "admin/user/images"

export const UPDATE_PREMIUM = "admin/user/premium";

export const SUPPORTLIST = "admin/user/supportList";

export const VERIFICATION_LIST = "admin/user/identification";

export const REUPLOAD_PROFILE_IMAGE = "admin/user/reuploadProfileImages";

export const VERIFY_MULTIPLE = "admin/user/approveMultipleImages";

export const REFFERAl_LIST = "admin/referral/codes";

export const REDEEM_LIST = "admin/referral/list";

export const USER_REFERRAL_LIST = "admin/referral/userReferalList";

export const USER_REFERRAL_AMOUNT = "admin/referral/amountPayed";

export const FQA_LIST = "admin/user/faq";

export const FQA_LIST_ORDER = "admin/user/reorderFaq";

export const FQA_CATEGORY_ORDER ="admin/user/reorder/faqCategory"

export const FQA_CATEGORY_LIST = "admin/user/faqCategory";

export const FQA_SUBCATEGORY_LIST = "admin/user/faqSubCategory";

export const WITHDRAWAL_REQUESTS = "admin/user/withdrawalRequests";

export const GRAPH_DATA = "admin/user/stats";

/////////////post/////////////////

export const POSTS = "admin/user/post";


////////////reports //////////////////////

export const REPORTS_LIST = "admin/user/report";

