import { OverlayTrigger, Tooltip } from "react-bootstrap";
import icon from "../../images/svg/exclamation.svg";
function WarningIconWithTooltip() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This user has uploaded inappropriate content before.
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span className="warning-icon ms-2 pointer">
        <img src={icon} alt="warning" width={24}/>
      </span>
    </OverlayTrigger>
  );
}

export default WarningIconWithTooltip;
