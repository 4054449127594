import {
    ACTIVE_STATUS_FILTER_POSTS,
    FORM_FILLED_FILTER_POSTS,
    GENDER_FILTER_POSTS,
    GENDER_STATUS_FILTER_POSTS,
    RESET_FILTER_POSTS,
    SEARCH_FILTER_POSTS,
    STATUS_FILTER_POSTS,
    TYPE_FILTER_POSTS,
} from '../actions/PostTypes';

const initialState = {
    filter: {
        search: "",
        gender:"all",
        status:"all",
        formFilled:"all",
        type:"all",
        activeStatus:"createdAt"  
    },
};

export default function FilterReducer(state = initialState, action) {
    if (action.type === SEARCH_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, search: action.payload },
        };
      }
      if (action.type === GENDER_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, gender: action.payload },
        };
      }
      if (action.type === STATUS_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, status: action.payload },
        };
      }
      if (action.type === FORM_FILLED_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, formFilled: action.payload },
        };
      }
      if (action.type === TYPE_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, type: action.payload },
        };
      }
      if (action.type === ACTIVE_STATUS_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, activeStatus: action.payload },
        };
      }
      if (action.type === GENDER_STATUS_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, gender: action.payload.gender, status: action.payload.status},
        };
      }
      if (action.type === RESET_FILTER_POSTS) {
        return {
          ...state,
          filter: { ...state.filter, gender: "all", status: "all", formFilled: "all", type: "all", activeStatus: "createdAt" },
        };
      }
   
    return state;
}
