import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { useLocation } from "react-router-dom";
import FaceVerification from "./FaceVerification";
import PremiumUser from "./PremiumUser";
import PremiumUserPast from "./PremiumUserPast";

export default function MemberTabs() {
  const location = useLocation();

  console.log("jhgfhjgdshgfhj", location?.state?.selectedTab);

  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab
      ? location?.state?.selectedTab
      : "Current Members"
  );

  const tabData = [
    { name: "Current Members", component: <PremiumUser /> },
    { name: "Past Members", component: <PremiumUserPast /> },
  ];

  return (
    <div>
      <PageTitle activeMenu="Members List" motherMenu="Members Management" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name}>{data.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data.name}
                    onClick={() => setSelectedTab(data.name)}
                    key={i}
                  >
                    {data.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
