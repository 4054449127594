import React, { useState, useContext } from "react";
/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

//import EcomProductDetails from "./components/Dashboard/EcomProductDetails";
import Task from "./components/Dashboard/Task";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserList from "./pages/UserList";
import SupportRequests from "./pages/SupportRequests";
import UserDetails from "./pages/UserDetails";
import Notification from "./pages/Notification";
import RefferalCodes from "./pages/RefferalCodes";
import RedeemBy from "./pages/RedeemBy";

import FAQs from "./pages/FAQs";
import FAQsList from "./pages/FAQsList";
import FAQSubcategory from "./pages/FAQSubcategory";
import WithdrawalList from "./pages/WithdrawalList";
import EditFaqs from "./pages/EditFaqs";
import CreateFaq from "./pages/CreateFaq";
import WithdrawalHistory from "./pages/WithdrawalHistory";
import PayedByAdmin from "./pages/PayedByAdmin";
import PostManagement from "./pages/PostManagement";
import ReportsManagement from "./pages/ReportsManagement";
import ReportedByUsers from "./pages/ReportedByUsers";
import PremiumUserDetails from "./pages/PremiumUserDetails";
import FaceTabs from "./pages/faceTabs";
import MemberTabs from "./pages/memberTabs";
import VerificationUserDetails from "./pages/VerificationUserDetails";

//frontend end

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    { url: "task", component: <Task /> },

    /// Form

    /// pages
    { url: "page-register", component: <Registration /> },
    { url: "page-lock-screen", component: <LockScreen /> },
    { url: "user-management", component: <UserList /> },
    { url: "members", component: <MemberTabs /> },
    { url: "support-requests", component: <SupportRequests /> },
    { url: "user-details", component: <UserDetails /> },
    { url: "verification-user-details", component: <VerificationUserDetails /> },
    { url: "member-details", component: <PremiumUserDetails /> },
    { url: "push-notifications", component: <Notification /> },
    { url: "referral-codes", component: <RefferalCodes /> },
    { url: "redeemed-by", component: <RedeemBy /> },
    { url: "faqs-category-list", component: <FAQs /> },
    // { url: "faqs-subcategory-list", component: <FAQSubcategory /> },
    { url: "faqs-list", component: <FAQsList /> },
    { url: "create-faqs", component: <CreateFaq /> },
    { url: "edit-faqs", component: <EditFaqs /> },
    { url: "withdrawal-requests", component: <WithdrawalList /> },
    { url: "face-verification", component: <FaceTabs /> },
    { url: "withdrawal-history", component: <WithdrawalHistory /> },
    { url: "payed-by-admin", component: <PayedByAdmin /> },
    { url: "post-management", component: <PostManagement /> },
    { url: "report-management", component: <ReportsManagement /> },
    { url: "report-details", component: <ReportedByUsers /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  //const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div id="main-wrapper" className={`show`}>
        {/* <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>   */}
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
function FrontLayout() {
  return (
    <>
      <div className="overflow-unset">
        {/* <Layout /> */}
        <Outlet />
      </div>
    </>
  );
}

export default Markup;
