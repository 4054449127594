
export const CREATE_POST_ACTION = '[Post Action] Create Post';
export const CONFIRMED_CREATE_POST_ACTION =
    '[Post Action] Confirmed Create Post';
export const GET_POSTS = '[Post Action] Get Posts';
export const CONFIRMED_GET_POSTS = '[Post Action] Confirmed Get Posts';
export const EDIT_POST_ACTION = '[Post Action] Edit Post';
export const CONFIRMED_EDIT_POST_ACTION =
    '[Post Action] Confirmed Edit Post';
export const CONFIRMED_DELETE_POST_ACTION =
    '[Post Action] Confirmed Delete Post';
export const SEARCH_FILTER_POSTS = '[Post Action] Search Filter Posts';
export const GENDER_FILTER_POSTS = '[Post Action] Gender Filter Posts';
export const STATUS_FILTER_POSTS = '[Post Action] Status Filter Posts';
export const FORM_FILLED_FILTER_POSTS = '[Post Action] Form Filled Filter Posts';
export const TYPE_FILTER_POSTS = '[Post Action] Type Filter Posts';
export const ACTIVE_STATUS_FILTER_POSTS = '[Post Action] Active Status Filter Posts';
export const GENDER_STATUS_FILTER_POSTS = '[Post Action] Gender Status Filter Posts';
export const RESET_FILTER_POSTS = '[Post Action] Reset Filter Posts';