import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ReportMessages = ({ show, text, data, onHide }) => {
  console.log(data);

  const reportReason = [
    {
      name: "Inappropriate Messages or Content",
      id: "1",
    },
    { name: "Fake or Misleading Profile", id: "2" },
    { name: "Harassment or Bullying", id: "3" },
    { name: "Unwanted Solicitation or Spam", id: "4" },
    { name: "Scamming or Fraudulent Activity", id: "5" },
    { name: "Profile Picture Violation", id: "6" },
    { name: "Underage User", id: "7" },
    { name: "Offensive Behavior", id: "8" },
    { name: "Other", id: "9" },
  ];

  const matchingNames = reportReason
    .filter((item) => data.includes(item.id))
    .map((item) => item.name);

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="modal-body">
        {matchingNames.length === 0 && (
          <ul>
            {data.map((item, index) => {
              return (
                item != "Other" && (
                  <li>
                    <spnan style={{ fontWight: "bold", color: "black" }}>
                      {index + 1}.{" "}
                    </spnan>
                    {item}
                  </li>
                )
              );
            })}
            {text && (
              <li>
                <spnan style={{ fontWight: "bold", color: "black" }}>
                  Other:{" "}
                </spnan>{" "}
                {text}
              </li>
            )}
          </ul>
        )}
        {matchingNames.length > 0 && (
          <ul>
            {matchingNames.map((item, index) => {
              return (
                item != "Other" && (
                  <li>
                    <spnan style={{ fontWight: "bold", color: "black" }}>
                      {index + 1}.{" "}
                    </spnan>
                    {item}
                  </li>
                )
              );
            })}
            {text && (
              <li>
                <spnan style={{ fontWight: "bold", color: "black" }}>
                  Other:{" "}
                </spnan>{" "}
                {text}
              </li>
            )}
          </ul>
        )}
      </div>
      {/* <div className="modal-footer"> */}
      <button
        type="button"
        onClick={onHide}
        className="btn btn-danger"
        style={{ borderRadius: "0px" }}
      >
        <i className="flaticon-delete-1"></i> Cancel
      </button>
      {/* </div> */}
    </Modal>
  );
};

export default ReportMessages;
