import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { useLocation } from "react-router-dom";
import PremiumUserPastTable from "./PremiumUsersPastTable";

export default function PremiumUserPast() {
  const location = useLocation();

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="default-tab">
            <PremiumUserPastTable />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
