import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";

import Spinner from "../components/Common/Spinner";

import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import ReferralCode from "../components/Modal/ReferralCode";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ReportMessages from "../components/Modal/ReportMessages";

export default function ReportedByUsers() {
  const [loader, setLoader] = useState(false);
  const [reportMessage,setReportMessage] = useState([]);
  const [reportDescription,setReportDescription] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const datas = location?.state?.data;

  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="Reports Management" previousPath={"/report-management"}/>
      <Card>
        <Card.Header>
          <Card.Title>Reported By</Card.Title>
        </Card.Header>
        <Card.Body className=" pt-3">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive className="mostly-customized-scrollbar mb-0">
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>name</strong>
                  </th>
                  <th>
                    <strong>Report Date</strong>
                  </th>
                  <th>
                    <strong>Reason</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {datas &&
                  datas?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.user ? item?.user?.name : "-"}</td>
                      <td>
                        {moment(item?.timestamp).format("MMM Do YYYY, h:mm A")}
                      </td>
                      {item?.reason.length > 0 && <td className="pointer" style={{ color: "#007bff" }} onClick={()=>{setIsExpanded(true);setReportMessage(item?.reason);setReportDescription(item?.description)}}>
                        Report Messages
                      </td>}
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {datas?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
      {isExpanded && (
        <ReportMessages
          show={isExpanded}
          text={reportDescription}
          data={reportMessage}
          onHide={() => setIsExpanded(false)}
        />
      )}
    </div>
  );
}
