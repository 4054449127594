import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { useLocation } from "react-router-dom";
import dummmyImg from "../../images/profile/images.jpeg";
import primiumBadge from "../../images/svg/badge.svg";
import verification1 from "../../images/svg/verified1.svg";
import verification2 from "../../images/svg/verified2.svg";
import FullImage from "../components/Modal/FullImage";
import diamond from "../../images/svg/badgeWithoutBg.svg";

import {
  approveRejectRequest,
  blockUser,
  deleteUser,
  getUserDetails,
  updateNonPremium,
} from "../../services/UserServices/UserService";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import ReasonModal from "../components/Modal/ReasonModal";
import Spinner from "../components/Common/Spinner";
import crossIcon from "../../images/svg/deleteIcon.svg";
import ReasonForPicDelete from "../components/Modal/ReasonForPicDelete";
import lock from "../../images/svg/lock.svg";
import WarningIconWithTooltip from "../components/WarningIconWithToolTip";
import PremiumUser from "../components/Modal/PremiumUser";

export default function VerificationUserDetails() {
  const location = useLocation();
  const userId = location?.state?.data;
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [data, setData] = useState({});
  const [images, setImages] = useState(data?.images || []);
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [type, setType] = useState(false);
  const [updatedImages, setUpdatedImages] = useState([]);
  const [showReasonToDeleteImage, setShowReasonToDeleteImage] = useState(false);
  const [premiumUser, setPremiumUser] = useState(false);
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="12" cy="5" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="19" r="2"></circle>
      </g>
    </svg>
  );

  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getUserDetails(userId);
      setData(response.data?.data);
      setImages(response.data?.data?.images);
      setUpdatedImages([]);
      console.log(response.data?.data);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      notifyTopRight(response.data?.message);
      handleFetch();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      handleFetch();

      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  const onApprove = async (id, type) => {
    setLoader(true);
    try {
      const payload = { userId: id, type: type, status: "accepted" };
      const response = await approveRejectRequest(payload);
      handleFetch();
      notifyTopRight(response.data?.message);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  const onPremium = async (id) => {
    setPremiumUser(true)
  };

  const NonPremium = async (id) => {
    setLoader(true);
    try {
      const response = await updateNonPremium(id);
      notifyTopRight(response.data?.message);
      handleFetch();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  /////image with cross icon
  const ImageWithCrossIcon = ({ image, index, isPrivate, onRemove }) => (
    <div className="position-relative">
      <img
        src={crossIcon}
        alt="Cross Icon"
        className={`cross-icon pointer  ${image ? "" : "d-none"}`}
        onClick={() => onRemove(index)}
      />
      <img
        src={image ? imgBaseURl + image : dummmyImg}
        width={index === 0 ? 177 : 83}
        height={index === 0 ? 177 : 83}
        className="text-center pointer rounded"
        style={{ transition: "width 0.5s, height 0.5s" }}
        onClick={() => {
          if (image) {
            setImageForView(imgBaseURl + image);
            setView(true);
          }
        }}
      />
      {isPrivate && (
        <img
          src={lock}
          alt="lock"
          style={{ position: "absolute", bottom: "5px", right: "5px" }}
        />
      )}
    </div>
  );
  ///function to handle remove image by index
  const handleRemoveImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages
        .filter((_, index) => index !== indexToRemove)
        .map((image, index) =>
          index >= indexToRemove ? images[index + 1] : image
        )
    );
    setUpdatedImages(images);
  };
  const handleUpdateImages = async () => {
    setShowReasonToDeleteImage(true);
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <div>
      <PageTitle
        activeMenu="User Details"
        motherMenu="User Management"
        previousPath={"/face-verification"}
        stateData={location?.state?.selectedTab}
        stateDataPage={location?.state?.page}
        search={location?.state?.search}
      />
      <Card>
        {loader ? (
          <Spinner />
        ) : (
          <Card.Header className="d-block align-items-start">
            <div
              className="d-block d-sm-flex justify-content-start"
              style={{ gap: "1rem" }}
            >
              <div>
                <div>
                  <div className="d-flex  gap-2">
                    {[0].map((index) => (
                      <ImageWithCrossIcon
                        key={index}
                        image={images[index]?.url}
                        index={index}
                        isPrivate={images[index]?.isLocked}
                        onRemove={handleRemoveImage}
                      />
                    ))}

                    <div className="d-flex flex-column gap-2 justify-content-between">
                      {[1, 2].map((index) => (
                        <ImageWithCrossIcon
                          key={index}
                          image={images[index]?.url}
                          index={index}
                          isPrivate={images[index]?.isLocked}
                          onRemove={handleRemoveImage}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="d-flex pt-3 mb-3 gap-2">
                    {[3, 4, 5].map((index) => (
                      <ImageWithCrossIcon
                        key={index}
                        image={images[index]?.url}
                        index={index}
                        isPrivate={images[index]?.isLocked}
                        onRemove={handleRemoveImage}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="text-left flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="text-color">
                    {data?.name}{" "}
                    {data?.isDeleted ? (
                      <Badge bg="" className="badge-secondary fs-12">
                        Deleted
                      </Badge>
                    ) : data?.isDeactivated ? (
                      <Badge bg="" className="badge-warning fs-12">
                        Deactivated
                      </Badge>
                    ) : data?.isBlocked ? (
                      <Badge bg="" className="badge-danger fs-12">
                        Disabled
                      </Badge>
                    ) : (
                      !data?.isBlocked && (
                        <Badge bg="" className="badge-success fs-12">
                          Enabled
                        </Badge>
                      )
                    )}
                    {data?.reasonsToDeleteImages && <WarningIconWithTooltip />}
                  </h3>
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    {data?.isPremium && data?.gender === "male" && (
                      <div className="bg-circle-diamond">
                      <img src={diamond} />
                      </div>
                    )}
                    {data?.verificationId?.status === "accepted" && (
                      <div className="bg-circle">
                        <img src={verification1} />
                      </div>
                    )}
                    {data?.verificationImage?.adminAction === "accepted" && (
                      <div className="bg-circle">
                        <img src={verification2} />
                      </div>
                    )}
                    <div>
                      {updatedImages?.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={handleUpdateImages}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="info light"
                        className="light sharp btn btn-info i-false p-0"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {data.isBlocked ? (
                          <Dropdown.Item onClick={() => onAction(data._id)}>
                            Enable
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item onClick={() => onAction(data._id)}>
                            Disable
                          </Dropdown.Item>
                        )}
                        {data?.isPremium ? (
                          <Dropdown.Item onClick={() => NonPremium(data._id)}>
                            Non Member
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item onClick={() => onPremium(data._id)}>
                            Member
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => onDelete(data._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="d-block d-md-flex gap-5">
                  <div>
                    <p>
                      Gender - <b>{data?.gender}</b>
                    </p>
                    <p>
                      Age - <b>{data?.age}</b>
                    </p>
                    <p>
                      Height - <b>{data?.height?.name}</b>
                    </p>
                    <p>
                      Income - <b>{data?.income?.name}</b>
                    </p>
                    <p>
                      Languages -{" "}
                      <b>
                        {data?.languages
                          ?.map((language) => language.name)
                          .join(", ")}
                      </b>
                    </p>
                    <p>
                      Created At -{" "}
                      <b>
                        {data?.createdAt &&
                          moment(data?.createdAt).format("MMM Do YYYY, h:mm a")}
                      </b>
                    </p>
                  </div>
                  <div className="">
                    <p>
                      Email - <b>{data?.email}</b>
                    </p>

                    <p>
                      Phone Number -{" "}
                      <b>
                        {data?.countryCode && "+" + data?.countryCode}{" "}
                        {data?.phoneNumber}
                      </b>
                    </p>
                    <p>
                      Body Type - <b>{data?.bodyType?.name}</b>
                    </p>
                    <p>
                      Net Worth - <b>{data?.netWorth?.name}</b>
                    </p>
                    <p>
                      Last Active -{" "}
                      <b>
                        {data?.lastApiCall &&
                          moment
                            .unix(data?.lastApiCall)
                            .format("MMM Do YYYY, h:mm a")}
                      </b>
                    </p>
                    <p>
                      conversation Count - <b>{data?.conversationCount}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
        )}
      </Card>
      {data?.verificationImage?.url && (
        <Card>
          {loader ? (
            <Spinner />
          ) : (
            <Card.Header className="d-block align-items-start">
              <div className="d-flex justify-content-between">
                <Card.Title>Verification Image</Card.Title>
                <div className="d-flex justify-content-between align-items-center gap-1">
                  {data?.gender === "male" ? (
                    data?.verificationImage?.status === "pending" ? (
                      <Badge bg="" className="badge-warning">
                        Pending
                      </Badge>
                    ) : data?.verificationImage?.status === "accepted" ? (
                      <Badge bg="" className="badge-success">
                        Approved
                      </Badge>
                    ) : (
                      <Badge bg="" className="badge-danger">
                        Rejected
                      </Badge>
                    )
                  ) : data?.gender === "female" ? (
                    data?.verificationImage?.adminAction === "pending" ? (
                      <Badge bg="" className="badge-warning">
                        Auto Approved
                      </Badge>
                    ) : data?.verificationImage?.adminAction === "accepted" ? (
                      <Badge bg="" className="badge-success">
                        Approved
                      </Badge>
                    ) : (
                      <Badge bg="" className="badge-danger">
                        Rejected
                      </Badge>
                    )
                  ) : (
                    ""
                  )}

                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info light"
                      className="light sharp btn btn-info i-false p-0"
                    >
                      {svg1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {data?.verificationImage?.adminAction === "pending" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => onApprove(data._id, "image")}
                          >
                            Approve
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setShowRejectModal(true);
                              setType("image");
                            }}
                          >
                            Reject
                          </Dropdown.Item>
                        </>
                      ) : data?.verificationImage?.adminAction ===
                        "accepted" ? (
                        <Dropdown.Item
                          onClick={() => {
                            setShowRejectModal(true);
                            setType("image");
                          }}
                        >
                          Reject
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => onApprove(data._id, "image")}
                        >
                          Approve
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div
                className="d-block d-sm-flex justify-content-start mt-3"
                style={{ gap: "1rem" }}
              >
                <div>
                  <img
                    src={
                      data?.verificationImage?.url
                        ? imgBaseURl + data?.verificationImage?.url
                        : dummmyImg
                    }
                    width={150}
                    height={150}
                    className="rounded pointer"
                    onClick={() => {
                      setImageForView(
                        imgBaseURl + data?.verificationImage?.url
                      );
                      setView(true);
                    }}
                  />
                </div>
              </div>
            </Card.Header>
          )}
        </Card>
      )}
      {data?.verificationId && (
        <Card>
          {loader ? (
            <Spinner />
          ) : (
            <Card.Header className="d-block align-items-start">
              <div className="d-flex justify-content-between">
                <Card.Title>Verification Id</Card.Title>
                <div className="d-flex justify-content-between align-items-center gap-1">
                  {data?.verificationId?.status === "accepted" ? (
                    <Badge bg="" className="badge-success">
                      Verified
                    </Badge>
                  ) : data?.verificationId?.status === "rejected" ? (
                    <Badge bg="" className="badge-danger">
                      Rejected
                    </Badge>
                  ) : (
                    <Badge bg="" className="badge-warning">
                      Pending
                    </Badge>
                  )}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info light"
                      className="light sharp btn btn-info i-false p-0"
                    >
                      {svg1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {data?.verificationId?.status === "pending" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => onApprove(data._id, "id")}
                          >
                            Approve
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setShowRejectModal(true);
                              setType("id");
                            }}
                          >
                            Reject
                          </Dropdown.Item>
                        </>
                      ) : data?.verificationId?.status === "accepted" ? (
                        <Dropdown.Item
                          onClick={() => {
                            setShowRejectModal(true);
                            setType("id");
                          }}
                        >
                          Reject
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => onApprove(data._id, "id")}
                        >
                          Approve
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="mt-2">
                <p>
                  <b>Document Type :</b> {data?.verificationId?.documentType}
                </p>
              </div>
              <div
                className="d-block d-sm-flex justify-content-start mt-3"
                style={{ gap: "1rem" }}
              >
                <div className="d-flex gap-3">
                  <img
                    src={
                      data?.verificationId?.frontUrl
                        ? imgBaseURl + data?.verificationId?.frontUrl
                        : dummmyImg
                    }
                    width={150}
                    height={150}
                    className="rounded pointer"
                    onClick={() => {
                      setImageForView(
                        imgBaseURl + data?.verificationId?.frontUrl
                      );
                      setView(true);
                    }}
                  />
                  <img
                    src={
                      data?.verificationId?.backUrl
                        ? imgBaseURl + data?.verificationId?.backUrl
                        : dummmyImg
                    }
                    width={150}
                    height={150}
                    className="rounded pointer"
                    onClick={() => {
                      setImageForView(
                        imgBaseURl + data?.verificationId?.backUrl
                      );
                      setView(true);
                    }}
                  />
                </div>
              </div>
            </Card.Header>
          )}
        </Card>
      )}
      {showRejectModal && (
        <ReasonModal
          show={showRejectModal}
          id={userId}
          type={type}
          table={handleFetch}
          onHide={() => setShowRejectModal(false)}
        />
      )}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
      {showReasonToDeleteImage && (
        <ReasonForPicDelete
          show={showReasonToDeleteImage}
          id={userId}
          images={images}
          table={handleFetch}
          onHide={() => setShowReasonToDeleteImage(false)}
        />
      )}
      {premiumUser && (
        <PremiumUser
          show={premiumUser}
          id={userId}
          table={handleFetch}
          setLoader={setLoader}
          onHide={() => setPremiumUser(false)}
        />
      )}
    </div>
  );
}
