import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const DisplayDeleteMessage = ({ show, text, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered>
        <div className="modal-body" style={{textAlign:"center"}}>
          <p style={{fontSize:"18px"}}>{text}</p>
        </div>
        {/* <div className="modal-footer"> */}
          <button type="button" onClick={onHide} className="btn btn-danger" style={{borderRadius:"0px"}}>
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        {/* </div> */}
    </Modal>
  );
};

export default DisplayDeleteMessage;
