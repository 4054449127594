import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { approveRejectRequest, payNow, updatePremium } from "../../../services/UserServices/UserService";

const PremiumUser = ({ show, id,table,setLoader,onHide }) => {
  const [days, setDays] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

      setLoader(true);
      try {
        const payload = {
          userId: id,
          days: +days,
        };
        await updatePremium(payload);
        notifyTopRight("Successfully");
        table();
        onHide();
        setLoader(false);
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
        setLoader(false);
      }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Premium User</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Days</label>
            <input
              type="number"
              className="form-control"
              value={days}
              min={0}
              onChange={(e) => {
                setDays(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PremiumUser;
