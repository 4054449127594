import { ACTIVE_STATUS_FILTER_POSTS, FORM_FILLED_FILTER_POSTS, GENDER_FILTER_POSTS, GENDER_STATUS_FILTER_POSTS, RESET_FILTER_POSTS, SEARCH_FILTER_POSTS, STATUS_FILTER_POSTS, TYPE_FILTER_POSTS } from "./PostTypes";


export function serachFilterPost(data) {
    return {
        type: SEARCH_FILTER_POSTS,
        payload: data,
    };
}

export function genderFilterPost(data) {
    return {
        type: GENDER_FILTER_POSTS,
        payload: data,
    };
}

export function statusFilterPost(data) {
    return {
        type: STATUS_FILTER_POSTS,
        payload: data,
    };
}

export function formFilledFilterPost(data) {
    return {
        type: FORM_FILLED_FILTER_POSTS,
        payload: data,
    };
}

export function typeFilterPost(data) {
    return {
        type: TYPE_FILTER_POSTS,
        payload: data,
    };
}

export function activeStatusFilterPost(data) {
    return {
        type: ACTIVE_STATUS_FILTER_POSTS,
        payload: data,
    };
}

export function genderStatusFilterPost(data) {
    return {
        type: GENDER_STATUS_FILTER_POSTS,
        payload: data,
    };
}

export function resetFilterPost() {
    return {
        type: RESET_FILTER_POSTS,
    };
}