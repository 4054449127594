import axios from "axios";
import { loginConfirmedAction, Logout } from "../../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN } from "./AuthEndPoints";
import axiosInstance from "../AxiosInstance";
import { notifyError } from "../../jsx/components/Common/Toaster";
import AWS from "aws-sdk";
export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.post(LOGIN, postData);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}

export function dashBoard(type) {
  return axiosInstance.get(DASHBOARD +`?lastActive=${type}`);
}

export function formatError(errorResponse) {
  notifyError(errorResponse);
  console.log(errorResponse, "error in auth");
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("matchCatchToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("matchCatchToken");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
export function isLogin() {
  const tokenDetailsString = localStorage.getItem("matchCatchToken");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}

const NEXT_PUBLIC_COGNITO_POOL_ID =
  process.env.REACT_APP_PUBLIC_COGNITO_POOL_ID;
const NEXT_PUBLIC_COGNITO_POOL_REGION =
  process.env.REACT_APP_PUBLIC_COGNITO_POOL_REGION;
const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_PUBLIC_S3_BUCKET_NAME;

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});

export default async function addPhoto(image, albumName) {
  // console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  // console.log(promise.imageName, promise,"promise");
  return promise;
}
