import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import {
  approveRejectRequest,
  getVerificationRequests,
} from "../../services/UserServices/UserService"; // Import your service
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import ReasonModal from "../components/Modal/ReasonModal";
import FullImage from "../components/Modal/FullImage";
import { useLocation, useNavigate } from "react-router-dom";

const VerificationRequestsTable = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [requests, setRequests] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [gender, setGender] = useState("all");
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("id");
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [status, setStatus] = useState("pending");
  const navigate = useNavigate();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const fetchRequests = async () => {
    setLoader(true);
    try {
      const response = await getVerificationRequests(
        currentPage,
        limit,
        type,
        status,
        gender,
        "all"
      );
      setRequests(response.data.data?.users);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  const onApprove = async (id) => {
    setLoader(true);
    try {
      const payload = { userId: id, type: type, status: "accepted" };
      const response = await approveRejectRequest(payload);
      fetchRequests();
      notifyTopRight(response.data?.message);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchRequests();
  }, [currentPage, type, status,gender]);

  return loader ? (
    <Spinner />
  ) : (
    <>
      <div className="d-flex align-items-center border-bottom gap-2 pb-3">
        {/* <div className="col-6"></div> */}
        <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Gender
              </label>

              <select
                className="form-control"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={"all"}>All</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
        <div className="mb-2 mb-md-0 w-100">
          <label className="text-black font-w600 fs-14">Filter by Status</label>
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={"pending"}>Pending</option>
            <option value={"accepted"}>Approved</option>
            <option value={"rejected"}>Rejected</option>
          </select>
        </div>
      </div>
      <Table responsive>
        <thead style={{ color: "black" }}>
          <tr>
            <th>
              <strong>NAME</strong>
            </th>
            <th>
              <strong>gender</strong>
            </th>
            <th>
              <strong>Image</strong>
            </th>
            {type === "id" && (
              <th>
                <strong>Back Image</strong>
              </th>
            )}
            {type === "id" && (
              <th>
                <strong>Document Type</strong>
              </th>
            )}
            <th>
              <strong>STATUS</strong>
            </th>
            <th>
              <strong>ACTION</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {requests?.map((item, i) => (
            <tr key={i}>
              <td
                onClick={() =>
                  navigate("/verification-user-details", {
                    state: {
                      data: item?._id,
                      selectedTab: "Verification Requests",
                      page: currentPage,
                    },
                  })
                }
                className="pointer"
              >
                {item?.name}
              </td>
              <td
                onClick={() =>
                  navigate("/Verification Requests", {
                    state: {
                      data: item?._id,
                      selectedTab: "verification requests",
                      page: currentPage,
                    },
                  })
                }
                className="pointer"
              >
                 {item?.gender &&
                        item?.gender?.charAt(0).toUpperCase() +
                          item?.gender?.slice(1)}
              </td>
              <td className="pointer">
               
                  <img
                    src={imgBaseURl + item?.verificationId?.frontUrl}
                    alt="img"
                    width={100}
                    height={80}
                    className="rounded"
                    onClick={() => {
                      setImageForView(
                        imgBaseURl + item?.verificationId?.frontUrl
                      );
                      setView(true);
                    }}
                  />
          
              </td>
              {type === "id" && (
                <td className="pointer">
                  <img
                    src={imgBaseURl + item?.verificationId?.backUrl}
                    alt="img"
                    width={100}
                    height={80}
                    className="rounded"
                    onClick={() => {
                      setImageForView(
                        imgBaseURl + item?.verificationId?.backUrl
                      );
                      setView(true);
                    }}
                  />
                </td>
              )}
              {type === "id" && (
                <td className="pointer">
                  {item?.verificationId?.documentType}
                </td>
              )}
              <td
                onClick={() =>
                  navigate("/verification-user-details", {
                    state: {
                      data: item?._id,
                      selectedTab: "Verification Requests",
                      page: currentPage,
                    },
                  })
                }
                className="pointer"
              >
                {item?.verificationId?.status === "pending" ? (
                  <Badge bg="" className="badge-warning">
                    Pending
                  </Badge>
                ) : item?.verificationId?.status === "accepted" ? (
                  <Badge bg="" className="badge-success">
                    Approved
                  </Badge>
                ) : (
                  <Badge bg="" className="badge-danger">
                    Rejected
                  </Badge>
                )}
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="info light"
                    className="light sharp btn btn-info i-false"
                  >
                    {svg1}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* Action items */}
                    {item?.verificationId?.status === "pending" ? (
                      <>
                        <Dropdown.Item onClick={() => onApprove(item?._id)}>
                          Approve
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowRejectModal(true);
                            setId(item?._id);
                          }}
                        >
                          Reject
                        </Dropdown.Item>
                      </>
                    ) : item?.verificationId?.status === "accepted" ? (
                      <Dropdown.Item
                        onClick={() => {
                          setShowRejectModal(true);
                          setId(item?._id);
                        }}
                      >
                        Reject
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item onClick={() => onApprove(item?._id)}>
                        Approve
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {requests?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {requests?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      {showRejectModal && (
        <ReasonModal
          show={showRejectModal}
          id={id}
          type={type}
          table={fetchRequests}
          onHide={() => setShowRejectModal(false)}
        />
      )}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
    </>
  );
};

export default VerificationRequestsTable;
