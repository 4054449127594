import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import {
  blockUser,
  deleteUser,
  getReportList,
  getWithdrawalRequests,
  withdrawalRequestAction,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";

import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import ReferralCode from "../components/Modal/ReferralCode";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function ReportsManagement() {
  const [loader, setLoader] = useState(false);

  const [datas, setDatas] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const limit = 10;
  const navigate = useNavigate();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  async function getTableData() {
    setLoader(true);
    try {
      const response = await getReportList(currentPage, limit);
      console.log(response.data, "12365");
      setDatas(response.data.data?.report);
      setLoader(false);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data.data?.count);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }

  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="Reports List" motherMenu="Reports Management" />
      <Card>
        <Card.Body className="pb-0 pt-3">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive className="mostly-customized-scrollbar mb-0">
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>name</strong>
                  </th>
                  <th>
                    <strong>Reported By</strong>
                  </th>
                  <th>
                    <strong>status</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {datas &&
                  datas?.map((item, i) => (
                    <tr key={i}>
                      <td
                        onClick={() =>
                          navigate("/user-details", {
                            state: {
                              data: item?.user?._id,
                              selectedTab: "users",
                              search: search,
                              page: currentPage,
                            },
                          })
                        }
                        className="pointer"
                      >
                        {item?.user ? item?.user?.name : "-"}
                      </td>
                      <td
                        className="pointer link-text"
                        onClick={() =>
                          navigate("/report-details", {
                            state: {
                              data: item?.reportedBy,
                              page: currentPage,
                            },
                          })
                        }
                      >
                        {item?.reportedBy?.length}
                      </td>
                      <td>
                        {item?.isDeleted ? (
                          <Badge bg="" className="badge-secondary">
                            Deleted
                          </Badge>
                        ) : item?.isDeactivated ? (
                          <Badge bg="" className="badge-warning">
                            Deactivated
                          </Badge>
                        ) : item?.isBlocked ? (
                          <Badge bg="" className="badge-danger">
                            Disabled
                          </Badge>
                        ) : (
                          !item?.isBlocked && (
                            <Badge bg="" className="badge-success">
                              Enabled
                            </Badge>
                          )
                        )}
                      </td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item?.user?.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item.user?._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item.user?._id)}>
                                Disable
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => onDelete(item.user?._id)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {datas?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {datas?.length !== 0 && (
          <div className="card-footer clearfix">
            <div className="d-block d-sm-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Showing {currentPage * limit + 1} to{" "}
                {listLength > (currentPage + 1) * limit
                  ? (currentPage + 1) * limit
                  : listLength}{" "}
                of {listLength} entries
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
