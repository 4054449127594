import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import {
  getRedeemedList,
  getUserReferralList,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import moment from "moment";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function RedeemBy() {
  const location = useLocation();
  const id = location?.state?.id;
  const show = location?.state?.table;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("all");
  const [filterStatus, setFilterStatus] = useState("accepted");
  const navigate = useNavigate();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  async function getTableData() {
    setLoader(true);
    try {
      const response = await getRedeemedList(currentPage, limit, type, id);
      console.log(response.data, "12365");
      setUsers(response.data.data?.users);
      setLoader(false);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data.data?.count);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }
  async function getTableData2() {
    setLoader(true);
    try {
      const response = await getUserReferralList(
        currentPage,
        limit,
        filterStatus,
        id
      );
      setUsers(response.data.data?.referals);
      setLoader(false);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data.data?.count);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  }
  useEffect(() => {
    if (show === "table1") {
      getTableData();
    } else {
      getTableData2();
    }
  }, [currentPage, type, filterStatus]);

  return (
    <div>
      {show === "table1" && (
        <>
          <PageTitle
            activeMenu="Redeemed By"
            motherMenu="Referral Codes"
            previousPath={"/referral-codes"}
            stateData={location?.state?.selectedTab}
            stateDataPage={location?.state?.page}
          />
          <Card>
            <Card.Header className="d-block p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div></div>

                <div>
                  <label className="text-black font-w600 fs-14">
                    Filter by Gender
                  </label>
                  <select
                    className="form-control"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={"all"}>All</option>
                    <option value={"male"}>Males</option>
                    <option value={"female"}>Females</option>
                  </select>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pb-0">
              {loader ? (
                <Spinner />
              ) : (
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item, i) => (
                      <tr key={i}>
                        <td
                          onClick={() =>
                            navigate("/user-details", {
                              state: { data: item?._id },
                            })
                          }
                          className="pointer"
                        >
                          {item.name}
                        </td>
                        <td>{moment(item?.createdAt).format("MMM Do YYYY, h:mm A")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {users?.length === 0 && !loader && (
                <div className="justify-content-center d-flex my-5">
                  Sorry, Data Not Found!
                </div>
              )}
            </Card.Body>
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card>
        </>
      )}
      {show === "table2" && (
        <>
          <PageTitle
            activeMenu="User Referrals List"
            motherMenu="Withdrawal Requests"
          />
          <Card>
            <Card.Header className="d-block p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div></div>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <div>
                    <label className="text-black font-w600 fs-14">
                      Filter by Status
                    </label>
                    <select
                      className="form-control"
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    >
                      <option value={"all"}>All</option>
                      <option value={"accepted"}>Available</option>
                      <option value={"pending"}>Pending</option>
                    </select>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pb-0">
              {loader ? (
                <Spinner />
              ) : (
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Earning</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item, i) => (
                      <tr key={i}>
                        <td
                          onClick={() =>
                            navigate("/user-details", {
                              state: { data: item?._id },
                            })
                          }
                          className="pointer"
                        >
                          {item?.user?.name}
                        </td>
                        <td>${item?.amount}</td>
                        <td>
                          {item?.status === "credit" ? (
                            <Badge bg="" className="badge-success">
                              Available
                            </Badge>
                          ) : (
                            <Badge bg="" className="badge-warning">
                              Pending
                            </Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {users?.length === 0 && !loader && (
                <div className="justify-content-center d-flex my-5">
                  Sorry, Data Not Found!
                </div>
              )}
            </Card.Body>
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card>
        </>
      )}
    </div>
  );
}
