import React from "react";
import { Modal } from "react-bootstrap";
import crossIcon from "../../../images/svg/deleteIcon.svg";

export default function FullImage({ show, image, onHide }) {
  return (
    <Modal
      className="modal fade image-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header className="border-0 w-100">
        <div className="image-container w-100" role="document">
        <div className="position-relative">
      <img
        src={crossIcon}
        alt="Cross Icon"
        className={`cross-icon pointer `}
        onClick={() => onHide()}
      />
          <img src={image} alt="img" className="w-100" />
        </div>
        </div>
      </Modal.Header>
    </Modal>
  );
}
